import {
	GoogleMap,
	InfoWindowF,
	MarkerF,
	useLoadScript,
} from "@react-google-maps/api";
import { useEffect, useState } from "react";
import { home } from "../../Variables";

const Map = (props) => {
	const { isLoaded } = useLoadScript({
		googleMapsApiKey: "AIzaSyCnb-bwtiLCu2G9Nb-5Ftc5EhuCAl8CE8A",
	});
	const [mapType, setMapType] = useState("");
	const [mapRef, setMapRef] = useState();
	const [isOpen, setIsOpen] = useState(false);
	const [infoWindowData, setInfoWindowData] = useState();
	const [ markers, setMarkers ] = useState( [] );
	const [ copied, setCopied ] = useState( false );
	const [ copiedData, setCopiedData ] = useState( "" );

	useEffect(() => {
		GetStations();
		const intervalId = setInterval(GetStations, 15000);
		return () => clearInterval(intervalId);
	}, [props.pollutant]);

	const GetStations = async () => {
		const requestOptions = {
			method: "GET",
			redirect: "follow",
		};

		fetch(home, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (Array.isArray(result.stations_array)) {
					if (props.pollutant === "gas") {
						const apiStations = result.stations_array.map((station) => ({
							address: station.title,
							lat: parseFloat(station.lat),
							lng: parseFloat(station.long),
							color: station.fillColour,
							id: station.id,
							aqi: station.aqi,
							bgColor: station.fillColour,
							aqiTitle: station.aqi_title,
						}));
						setMarkers(apiStations);
					} else if (props.pollutant === "pm") {
						const apiStations = result.stations_array.map((station) => ({
							address: station.title,
							lat: parseFloat(station.lat),
							lng: parseFloat(station.long),
							color: station.pfillColour,
							id: station.id,
							aqi: station.paqi,
							bgColor: station.pfillColour,
							aqiTitle: station.paqi_title,
						}));
						setMarkers(apiStations);
					}
				} else {
					console.error("Stations data is not an array:", result.stations);
				}
			})
			.catch((error) => console.error(error));
	};

	useEffect(() => {
		if (props.sate === false) {
			setMapType("satellite");
		} else if (props.road === false) {
			setMapType("roadmap");
		}
	}, [props.sate, props.road, mapType]);

	const onMapLoad = (map) => {
		setMapRef(map);
		const bounds = new window.google.maps.LatLngBounds();
		markers?.forEach(({ lat, lng }) => bounds.extend({ lat, lng }));
		map.fitBounds(bounds);
	};

	const handleMarkerClick = (id, lat, lng, address, aqi, bgColor, aqiTitle) => {
		// mapRef?.panTo({ lat, lng });
		setInfoWindowData({ id, address, aqi, bgColor, aqiTitle });
		setIsOpen(true);
	};

	const handleMarkerRightClick = (event, lat, lng) => {
		const coords = `${lat},${lng}`;
		navigator.clipboard.writeText(coords);
		setCopied( true );
		setIsOpen(false);
		setCopiedData(`Lat: ${lat}, Long: ${lng}`);
		setTimeout(() => setCopied(false), 2000);
		return false;
	};

	const loadMap = markers.length > 0;

	return (
		<div className="App">
			{!isLoaded ? (
				<h1>Loading...</h1>
			) : (
				loadMap && (
					<GoogleMap
						mapContainerClassName="map-container -mt-[3.8vw] sm:h-[98dvh] lm:h-[98vh] tab:h-[99vh] sl:h-[99vh] lt:h-[100vh]"
						onLoad={onMapLoad}
						zoom={8}
						mapTypeId={mapType}
					>
						{markers.map(
							(
								{ address, lat, lng, color, id, aqi, bgColor, aqiTitle },
								ind
							) => (
								<MarkerF
									key={ind}
									position={{ lat, lng }}
									onMouseOver={() => {
										handleMarkerClick(
											ind,
											lat,
											lng,
											address,
											aqi,
											bgColor,
											aqiTitle
										);
										setCopied(false);
									}}
									onMouseOut={() => {
										setIsOpen(false);
									}}
									onClick={() => {
										props.showChart(id);
										props.default(id);
									}}
									onRightClick={(event) =>
										handleMarkerRightClick(event, lat, lng)
									}
									icon={{
										url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(
											`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="${color}" width="40" height="40"><path d="M12 2c-4.42 0-8 3.58-8 8 0 5.25 8 14 8 14s8-8.75 8-14c0-4.42-3.58-8-8-8zm0 11.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/></svg>`
										)}`,
										anchor: new window.google.maps.Point(4, 4),
										scaledSize: new window.google.maps.Size(40, 40),
									}}
								>
									{isOpen && infoWindowData?.id === ind && (
										<InfoWindowF>
											<div>
												<h2
													className={`text-white font-[500] text-[1vw] w-[100%] h-[40%] px-[0.6vw] py-[0.4vw]`}
													style={{ background: bgColor }}
												>
													{infoWindowData.address + " " + `(${aqiTitle})`}
												</h2>
												<h3 className="font-[500] text-[1.06vw] w-[100%] h-[40%] px-[0.6vw] py-[1vw]">
													AQI: {aqi}
												</h3>
											</div>
										</InfoWindowF>
									)}

									{copied && infoWindowData?.id === ind && (
										<InfoWindowF>
											<div>
												<h3 className="text-center font-[500] text-[0.9vw] px-[0.2vw] py-[0.6vw] w-[100%] h-[40%]">
													{copiedData}
												</h3>
											</div>
										</InfoWindowF>
									)}
								</MarkerF>
							)
						)}
					</GoogleMap>
				)
			)}
		</div>
	);
};

export default Map;
